<template>
    <div v-if="showBanner" class="cookie-banner">
        <div :style="{transform: `translateX(${pageIndex * -100}%)`}" style="width: 100%; padding: 24px; transition: transform 0.2s ease;">
            <div class="t--400" style="display: flex; align-items: center; margin-bottom: 20px">
                <p class="notoKR" style="margin-right: 20px;">개인정보 보호</p>
                <div>
                    <lock-icon size="1.5x" class="custom-class"></lock-icon>
                </div>
            </div>
            <div class="cookie-banner--inner" :style="{display: innerWidth >= 768 ? 'flex': '', justifyContent: innerWidth >= 768 ? 'space-between': ''}">
                <p class="t--250 notoKR" :style="{width: innerWidth >= 768 ? 'calc(100% - 170px)': '', marginBottom: innerWidth >= 768 ? '': '30px'}" style="font-weight: 200; line-height: 1.4">YLOHPP는 개인정보를 중요하게 생각합니다. 당사 웹사이트의 원활한 사용과 사용자 여러분들의 편리한 경험을 위해 쿠키를 사용하고 있습니다. 쿠키를 허용하시면 카카오톡 채널 상담 등 다양한 서비스를 더욱 원활하게 이용하실 수 있습니다. 사용하도록 설정하면 <span @click="goPolicy()" style="font-weight: 500; text-decoration: underline;">쿠키정책</span>에 명시된 바에 따라 이에 동의하는 것입니다. 브라우저 설정을 통해 언제든지 동의를 철회할 수 있습니다.</p>
                <div class="cookie-settings" :style="{flexDirection: innerWidth >= 768 ? 'column': ''}" style="display: flex">
                    <button class="btn_black notoKR t--300" :style="{width: innerWidth >= 768 ? '150px': '100%', marginBottom: innerWidth >= 768 ? '10px': '0', marginRight: innerWidth >= 768 ? '': '20px'}" @click="acceptCookies">OK</button>
                    <button class="btn_white notoKR t--300" @click="pageIndex = 1" :style="{width: innerWidth >= 768 ? '150px': '100%'}">쿠키 설정</button>
                </div>
            </div>
        </div>
        <div :style="{transform: `translateX(${(-pageIndex + 1) * 100}%)`}" style="position: absolute; top: 0; left: 0; width: 100%; padding: 24px; transition: transform 0.2s ease;">
            <div class="t--400" style="display: flex; align-items: center; margin-bottom: 20px">
                <div @click="pageIndex = 0" style="border-radius: 4px; margin-right: 15px">
                    <chevron-left-icon size="2x" class="custom-class"></chevron-left-icon>
                </div>
                <p class="notoKR" style="margin-right: 20px;">쿠키 설정</p>
            </div>
            <div class="cookie-banner--inner" :style="{display: innerWidth >= 768 ? 'flex': '', justifyContent: innerWidth >= 768 ? 'space-between': ''}">
                <p class="t--250 notoKR" :style="{width: innerWidth >= 768 ? 'calc(100% - 170px)': '', marginBottom: innerWidth >= 768 ? '': '20px'}" style="font-weight: 200; line-height: 1.4">저희 웹사이트를 이용하실 때, 여러분의 장치에서 정보를 수집하고 저장하기 위해 저희와 저희 파트너사의 추적기를 사용할 수 있습니다. 이러한 추적기는 여러분의 웹사이트 경험을 개선하고, 맞춤형 광고를 제공하는 데 도움을 줍니다.
                여러분의 선호에 따라 아래에서 추적기 설정을 맞춤 설정할 수 있으며, 언제든지 설정을 변경하실 수 있습니다.</p>
            </div>
            <p class="notoKR t--600" style="margin-bottom: 10px">권한 관리</p>
            <hr style="width: 100%; height: 0.5px; border-bottom: 1px solid white; margin: 5px 0">
            <div style="display: flex; align-items: center; justify-content: space-between">
                <p class="notoKR t--250" style="margin-right: 20px;">필수 권한 허용</p>
                <CustomSwitch :switchData="switchData" @on="setCookie" @off="deleteCookie" />
            </div>
        </div>
    </div>
</template>
  
<script>
import CustomSwitch from '@/components/utillities/CustomSwitch.vue';
import { LockIcon, ChevronLeftIcon  } from 'vue-feather-icons'
export default {
    data() {
        return {
            showBanner: true,
            pageIndex: 0,
            switchData: {
                width: 32,
                height: 16,
                background: {
                    off: 'transparent',
                    on: 'white'
                },
                btnColor: {
                    off: 'white',
                    on: 'black'
                },
                border: 'white',
                padding: 1,
                btnSize: 12
            },
        };
    },
    components: {
        CustomSwitch,
        LockIcon,
        ChevronLeftIcon
    },
    computed: {
        innerWidth() {
            return this.$store.state.innerWidth
        }
    },
    methods: {
        acceptCookies() {
            if (this.getCookie('thirdPartyCookies') == 'accepted') {
                this.showBanner = false;
            } else {
                const date = new Date();
                date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30일 동안 유효
                document.cookie = `thirdPartyCookies=accepted; expires=${date.toUTCString()}; path=/`;
                this.showBanner = false;
                this.$emit('cookies-accepted');
            }
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },
        goPolicy() {
            if(this.$route.path != '/cookie-policy') {
                this.$store.commit('SET_IS_SPLASH', 'COOKIE')
                setTimeout(() => {
                    this.$router.push('/cookie-policy')
                }, 500);
            }
        },
        setCookie() {
            const date = new Date();
            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30일 동안 유효
            document.cookie = `thirdPartyCookies=accepted; expires=${date.toUTCString()}; path=/`;
            this.$emit('cookies-accepted');
        },
        deleteCookie() {
            document.cookie = 'thirdPartyCookies=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        }
    },
    mounted() {
        if (this.getCookie('thirdPartyCookies') == 'accepted') {
            this.showBanner = false;
            this.$emit('cookies-accepted');
        }
    }
};
</script>

<style lang="scss">
    .cookie-banner {
        position: fixed;
        display: flex;
        flex-wrap: nowrap;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 20px);
        background-color: #000000B3;
        color: #fff;
        border-radius: 10px;
        overflow: hidden;
        transition: transform 0.5s ease;
        .cookie-settings {
            button {
                border-radius: 5px;
                padding: 10px 0;
                font-weight: 300;
            }
        }
    }
</style>
/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
  SET_USER_MAIL(state, payload) {
    state.userEmail = payload
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload
  },
  LOG_OUT(state, payload) {
    state.userInfo = null
    localStorage.removeItem('accessToken')
  },
}

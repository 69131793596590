import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [{
    path: '',
    name: 'Home',
    component: () => import('@/layouts/FullPage.vue'),
      children: [
        {
          path: '/',
          redirect: '/home'
        },{
          path: '/home',
          name: 'Main',
          component: () => import('@/views/pages/web/main/Main.vue'),
        },{
          path: '/work',
          name: 'Work',
          component: () => import('@/views/pages/web/work/Main.vue'),
        },{
          path: '/services',
          name: 'Services',
          component: () => import('@/views/pages/web/service/Main.vue'),
        },{
          path: '/contact',
          name: 'Contact',
          component: () => import('@/views/pages/web/contact/Main.vue'),
        },{
          path: '/cookie-policy',
          name: 'Cookie',
          component: () => import('@/views/pages/web/randing/CookiePolicy.vue'),
        },{
          path: '/request',
          name: 'Request',
          component: () => import('@/views/pages/web/admin/Main.vue'),
        },
      ]
    },
    // {
    //   path: '/',
    //   name: 'TemplateHome',
    //   component: () => import('@/layouts/Template1Base.vue'),
    //     children: [
    //         {
    //           path: '/',
    //           redirect: '/template1/home'
    //         },{
    //           path: '/template1/home',
    //           name: 'TemplateMain',
    //           component: () => import('@/views/pages/template1/main/Main.vue'),
    //         },{
    //           path: '/template1/login',
    //           name: 'Login',
    //           component: () => import('@/views/pages/template1/auth/Login.vue'),
    //           meta: {
    //             'pageTitle': 'L O G I N',
    //             'pageTitle_kr': '로그인'
    //           }
    //         },
    //         // 약관
    //         {
    //           path: '/template1/terms-of-service',
    //           name: 'TermsOfService',
    //           component: () => import('@/views/pages/template1/legal/TermsOfService.vue'),
    //           meta: {
    //             'pageTitle': 'T E R M S \u00A0 O F \u00A0 S E R V I C E',
    //             'pageTitle_kr': '이용약관'
    //           }
    //         },{
    //           path: '/template1/privacy-policy',
    //           name: 'PrivacyPolicy',
    //           component: () => import('@/views/pages/template1/legal/PrivacyPolicy.vue'),
    //           meta: {
    //             'pageTitle': 'P R I V A C Y \u00A0 P O L I C Y',
    //             'pageTitle_kr': '개인정보처리방침',
    //           }
    //         },{
    //           path: '/template1/company',
    //           redirect: '/template1/company/intro',
    //           component: { render: (h) => h('router-view') },
    //           name: '회사소개',
    //           children: [
    //             // 회사소개
    //             {
    //               path: 'intro',
    //               name: 'Intro',
    //               component: () => import('@/views/pages/template1/company/Intro.vue'),
    //               meta: {
    //                 'pageTitle': 'I N T R O D U C T I O N',
    //                 'pageTitle_kr': '기업소개',
    //                 'topImg': '/sub/cytonn-photography-n95VMLxqM2I-unsplash.jpg'
    //               }
    //             },{
    //               path: 'greeting',
    //               name: 'Greeting',
    //               component: () => import('@/views/pages/template1/company/Greeting.vue'),
    //               meta: {
    //                 'pageTitle': 'G R E E T I N G',
    //                 'pageTitle_kr': '인사말',
    //                 'topImg': '/sub/cytonn-photography-n95VMLxqM2I-unsplash.jpg'
    //               }
    //             },{
    //               path: 'history',
    //               name: 'History',
    //               component: () => import('@/views/pages/template1/company/History.vue'),
    //               meta: {
    //                 'pageTitle': 'H I S T O R Y',
    //                 'pageTitle_kr': '연혁',
    //                 'topImg': '/sub/cytonn-photography-n95VMLxqM2I-unsplash.jpg'
    //               }
    //             },{
    //               path: 'office-location',
    //               name: 'Location',
    //               component: () => import('@/views/pages/template1/company/Location.vue'),
    //               meta: {
    //                 'pageTitle': 'L O C A T I O N',
    //                 'pageTitle_kr': '오시는길',
    //                 'topImg': '/sub/cytonn-photography-n95VMLxqM2I-unsplash.jpg'
    //               }
    //             },
    //           ]
    //         },{
    //           path: '/template1/business',
    //           redirect: '/template1/business/item',
    //           component: { render: (h) => h('router-view') },
    //           name: '사업분야',
    //           children: [
    //             // 사업분야
    //             {
    //               path: 'item',
    //               name: 'Business',
    //               component: () => import('@/views/pages/template1/business/Item.vue'),
    //               meta: {
    //                 'pageTitle': 'B U S I N E S S',
    //                 'pageTitle_kr': '사업분야',
    //                 'topImg': '/sub/luca-bravo-9l_326FISzk-unsplash.jpg'
    //               }
    //             },
    //           ]
    //         },{
    //           path: '/template1/product',
    //           redirect: '/template1/product/item',
    //           component: { render: (h) => h('router-view') },
    //           name: '제품소개',
    //           children: [
    //             // 제품소개
    //             {
    //               path: 'item',
    //               name: 'Product',
    //               component: () => import('@/views/pages/template1/product/Product.vue'),
    //               meta: {
    //                 'pageTitle': 'P R O D U C T',
    //                 'pageTitle_kr': '제품소개',
    //                 'topImg': ''
    //               }
    //             },{
    //               path: 'item/detail',
    //               name: 'Product-item',
    //               component: () => import('@/views/pages/template1/product/item/Content.vue'),
    //               meta: {
    //                 'pageTitle': 'P R O D U C T',
    //                 'pageTitle_kr': '제품소개',
    //                 'topImg': ''
    //               }
    //             },
    //           ]
    //         },{
    //           path: '/template1/customer',
    //           redirect: '/template1/customer/notice',
    //           component: { render: (h) => h('router-view') },
    //           name: '고객센터',
    //           children: [
    //             // 고객지원
    //             {
    //               path: 'notice',
    //               name: 'Notice',
    //               component: () => import('@/views/pages/template1/customer/Notice.vue'),
    //               meta: {
    //                 'pageTitle': 'N O T I C E',
    //                 'pageTitle_kr': '공지사항',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },{
    //               path: 'notice/edit',
    //               name: 'NoticeEdit',
    //               component: () => import('@/views/pages/template1/customer/notice/NoticeEdit.vue'),
    //               meta: {
    //                 'pageTitle': 'N O T I C E',
    //                 'pageTitle_kr': '공지사항',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },{
    //               path: 'notice/detail',
    //               name: 'NoticeDetail',
    //               component: () => import('@/views/pages/template1/customer/notice/NoticeDetail.vue'),
    //               meta: {
    //                 'pageTitle': 'N O T I C E',
    //                 'pageTitle_kr': '공지사항',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },{
    //               path: 'news',
    //               name: 'News',
    //               component: () => import('@/views/pages/template1/customer/News.vue'),
    //               meta: {
    //                 'pageTitle': 'N E W S',
    //                 'pageTitle_kr': '새소식',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },{
    //               path: 'news/edit',
    //               name: 'NewsEdit',
    //               component: () => import('@/views/pages/template1/customer/notice/NoticeEdit.vue'),
    //               meta: {
    //                 'pageTitle': 'N E W S',
    //                 'pageTitle_kr': '새소식',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },{
    //               path: 'news/detail',
    //               name: 'NewsDetail',
    //               component: () => import('@/views/pages/template1/customer/notice/NoticeDetail.vue'),
    //               meta: {
    //                 'pageTitle': 'N E W S',
    //                 'pageTitle_kr': '새소식',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },
    //             {
    //               path: 'qa',
    //               name: 'Qa',
    //               component: () => import('@/views/pages/template1/customer/Qa.vue'),
    //               meta: {
    //                 'pageTitle': 'Q & A',
    //                 'pageTitle_kr': '문의하기',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },{
    //               path: '/template1/customer/faq',
    //               name: 'Faq',
    //               component: () => import('@/views/pages/template1/customer/Faq.vue'),
    //               meta: {
    //                 'pageTitle': 'F A Q',
    //                 'pageTitle_kr': '자주묻는질문',
    //                 'topImg': '/sub/pexels-anna-nekrashevich-6801648.jpg'
    //               }
    //             },
    //           ]
    //         },{
    //           path: '/template1/site-map',
    //           name: 'SiteMap',
    //           component: () => import('@/views/pages/template1/main/Sitemap.vue')
    //         }
    //       ]
    //   }
  ],
  scrollBehavior (to, from, savedPosition) {
    window.previousRoute = from;
    return { x: 0, y: 0 }
  }
})
 

/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import jwt from "../../http/requests/auth/jwt/index.js"

export default {
  getNoticeList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/board_list&user_id=${payload.user_id}&type=${payload.type}`)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            var notice = JSON.parse(JSON.stringify(response.data.data)).filter((item) => {
                return item.type == '공지사항'
            })
            var news = JSON.parse(JSON.stringify(response.data.data)).filter((item) => {
                return item.type == '최신건설뉴스'
            })
            commit('SET_NOTICE_LIST',notice)
            commit('SET_NEWS_LIST', news)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getNoticeDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/board/detail?board_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_NOTICE_DETAIL', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createNotice({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.post("/api/board/create")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_NOTICE_DETAIL', null)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateNotice(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/board/update", payload)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteNotice(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/board?board_id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadFile(context, payload) {
    var frm = new FormData()
    frm.append("board_id",  payload.board_id)
    frm.append("file",  payload.file)
    for(let i in payload.file) {
      frm.append("file",  payload.file[i])
    }
    return new Promise((resolve, reject) => {
      axios.post("/api/board/file/create" , frm, {headers:{'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/board/file/download", payload, {responseType: 'blob'}).then((response)=>{
        resolve(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadFile(context, payload) {
    return new Promise((resolve, reject) => {
      const url = window.URL.createObjectURL(new Blob([payload], { type: payload.type }));
      const link = document.createElement('a')
      link.href = url;
      // const filename = replaceAll(decodeURI(response.headers.filename), '+', ' ')
      link.setAttribute('download', payload.name);

      document.body.appendChild(link);
      link.click();

      // 활성화
      // resolve(response)
      // axios.post("/api/board/file/download", payload, {responseType: 'blob'}).then((response)=>{
      //   const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      //   const link = document.createElement('a')
      //   link.href = url;
      //   // const filename = replaceAll(decodeURI(response.headers.filename), '+', ' ')
      //   link.setAttribute('download', payload.file_name);

      //   document.body.appendChild(link);
      //   link.click();
      //   resolve(response)
      // })
      //   .catch((error) => {
      //     reject(error)
      //   })
    })
  },
  deleteFile(context, payload) {
    return new Promise((resolve, reject) => {
      axios.delete("/api/board/file?id=" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

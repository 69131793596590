/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  filteredNote: state => JSON.parse(JSON.stringify(state.activeNoteList)).filter((note) => {
    if(!state.keyword.query) {
      return note
    } else {
      var target
      for(var key of Object.keys(note)) {
        if((note[key] == state.keyword.query) || (String(note[key]).includes(state.keyword.query))) {
          target = note
        }
      }
      return target
    }
  }),
  filteredProduct: state => JSON.parse(JSON.stringify(state.bomList)).filter((stock) => {
    if(!state.stockKeyword.query) {
      return stock
    } else {
      return (stock.name == state.stockKeyword.query) || (String(stock.name).includes(state.stockKeyword.query))
    }
  }),
  filteredProcess: state => JSON.parse(JSON.stringify(state.activeProcessList)).filter((process) => {
    if(!state.processKeyword.query) {
      return process
    } else {
      return (process.process_name == state.processKeyword.query) || (String(process.process_name).includes(state.processKeyword.query))
    }
  }),
}

export default getters

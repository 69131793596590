<template>
    <div @click="toggleBtn()" style="cursor: pointer">
        <div :style="{width: styleInfo.width + 'px' , height: styleInfo.height + 'px' , padding: styleInfo.padding + 'px' , background: styleInfo.background[state[switchState]], border: `1px solid ${styleInfo.border}`}" style="position: relative; border-radius: 31px;">
            <div :style="{width: styleInfo.btnSize + 'px', height: styleInfo.btnSize + 'px', background: styleInfo.btnColor[state[switchState]] , left: switchPosition + 'px' , top: styleInfo.padding + 'px'}" style="position: absolute; border-radius: 100%; box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%); transition:all 0.3s ease; box-shadow: 2px 2px 15px 8px rgba(0, 0, 0, 0.2);"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['switchData'],
    data() {
        return {
            switchState: 0,
            styleInfo: {
                width: 42,
                height: 22,
                background: {
                    off: '#C4C4C4',
                    on: '#1D77FF'
                },
                btnColor: {
                    off: '#FFFFFF',
                    on: '#FFFFFF'
                },
                border: '#FFFFFF',
                padding: 5,
                btnSize: 12
            },
            state: ['off' , 'on'],
            count: 0,
            switchPosition: 2
        }
    },
    computed: {
    },
    methods: {
        toggleBtn() {
            if(this.switchState == 0) {
                this.$emit('on')
                this.switchState = 1
                this.switchPosition = this.styleInfo.width - this.styleInfo.btnSize - 2
            } else {
                this.$emit('off')
                this.switchState = 0
                this.switchPosition = 2
            }
        }
    },
    created() {
        if(this.switchData) {
            for(const property of Object.keys(this.switchData)) {
                if(this.switchData[property] != null) {
                    this.$set(this.styleInfo , property , this.switchData[property])
                }
            }
        }
        if(this.switchState == 1) {
            this.switchPosition = this.styleInfo.width - this.styleInfo.btnSize - 2
        } else {
            this.switchPosition = 2
        }
    },
    mounted() {
        if(window.Kakao) {
            this.switchState = window.Kakao.isInitialized() ? 1 : 0
        }
    }
}
</script>
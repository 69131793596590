/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  notice: [],
  holidayList: [],
  eventList: [],
  activeMonth: null,
  // var job = ['ceo' , 'support' , 'auditor' , 'business' , 'plan' , 'product' , 'productSec' , 'field' , 'fieldSec' , 'fieldThird' , 'distribute']
  //   var name = ['대표이사' , '경영지원' , '감사' , '영업1팀' , '기획팀' , '제작1팀' , '제작2팀' , '현장1팀' , '현장2팀' , '현장3팀' , '물류팀']
  staffAll: [
    { 
      'id': 1,
      'name': '홍길동',
      'department': '대표이사',
      'duty': '대표',
      'phone': '010-1234-1234',
      'extension': '1000',
    },{
      'id': 2,
      'name': '홍길동',
      'department': '경영지원',
      'duty': '과장',
      'phone': '010-1234-1234',
      'extension': '1001',
    },{
      'id': 3,
      'name': '홍길동',
      'department': '경영지원',
      'duty': '대리',
      'phone': '010-1234-1234',
      'extension': '1002',
    },{
      'id': 4,
      'name': '홍길동',
      'department': '경영지원',
      'duty': '사원',
      'phone': '010-1234-1234',
      'extension': '1003',
    },{
      'id': 5,
      'name': '홍길동',
      'department': '감사',
      'duty': '',
      'phone': '010-1234-1234',
      'extension': '1004',
    },{
      'id': 6,
      'name': '홍길동',
      'department': '감사',
      'duty': '',
      'phone': '010-1234-1234',
      'extension': '1004',
    },
  ],
  staff: {
    ceo: [],
    support: [],
    auditor: [],
    business: [],
    plan: [],
    soso: [],
    product: [],
    productSec: [],
    field: [],
    fieldSec: [],
    fieldThird: [],
    distribute: [],
  }
}

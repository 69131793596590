// axios
import axios from 'axios'

const baseURL = "https://www.yellowhippy.kr:7770"
// const baseURL = "https://175.126.111.97:7770"

export default axios.create({
  baseURL: baseURL
  // You can add your headers here
})

/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"

export default {
  SET_NOTICE_LIST(state, payload) {
    state.noticeList = payload
  },
  SET_NEWS_LIST(state, payload) {
    state.newsList = payload
  },
  SET_ACTIVE_LIST(state, payload) {
    state.activeList = payload
  },
  SET_ACTIVE_INDEX(state, payload) {
    state.activeIndex = payload
  },
  SET_NOTICE_DETAIL(state, payload) {
    state.noticeDetail = payload
  },
  DELETE_NOTICE(state, payload) {
    var list = this.$route.path.includes('notice') ? state.noticeList : state.newsList
    var index = list.findIndex((item) => {
      return item.id == state.activeList[payload].id
    })
    if(index != -1) {
      Vue.delete(list, index)
      Vue.delete(state.activeList, payload)
    }
  },
  ADD_ACTIVE_LIST(state, payload) {
    Vue.set(payload.type == '공지사항' ? state.noticeList : state.newsList, state.activeList.length, payload)
    console.log(payload.type)
    console.log('공지: ' + state.noticeList)
    console.log('새소식: ' + state.newsList)
  },
}

/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"

export default {
  SET_USER_MAIL(state, payload) {
    state.userEmail = payload
  },
  SET_HOLIDAY_LIST(state, payload) {
    state.eventList = []
    // state.holidayList = []
    var response = payload.response.body.items.item
    if(response) {
      if(Array.isArray(response)) {
        for(var i in response) {
          // Vue.set(state.eventList, i, {
          Vue.set(state.eventList, i, {
            detail: response[i].dateName,
            date: response[i].locdate,  
            type: 0,
            require_hour: null
          })
        } 
      } else {
        Vue.set(state.eventList, 0, {
        // Vue.set(state.holidayList, 0, {
          detail: response.dateName,
          date: response.locdate,
          type: 0,
          require_hour: null
        })
      }
    }
    console.log(state.eventList)
  },
  SET_ACTIVE_MONTH(state, payload) {
    state.activeMonth = payload
  },
  SET_MEMBER_JOB_TYPE(state, payload) {
    var job = ['ceo' , 'support' , 'auditor' , 'business' , 'plan' , 'product' , 'productSec' , 'field' , 'fieldSec' , 'fieldThird' , 'distribute']
    var name = ['대표이사' , '경영지원' , '감사' , '영업1팀' , '기획팀' , '제작1팀' , '제작2팀' , '현장1팀' , '현장2팀' , '현장3팀' , '물류팀']
    for(var i=0; i<job.length; i++) {
      Vue.set(state.staff, job[i], payload.filter((item)=> {
        return item.department == name[i]
      }))
    }
  },
  ADD_NEW_USER(state, payload) {
    state.staffAll.push(payload)
  },
  EDIT_USER(state, payload) {
    var index = state.staffAll.findIndex((item) => {
      return item.id == payload.id
    })
    console.log(index)
    if(index != -1) {
      Vue.set(state.staffAll, index, payload)
    }
  },
  DELETE_USER(state, payload) {
    var index = state.staffAll.findIndex((item) => {
      return item.id == payload
    })
    if(index != -1) {
      Vue.delete(state.staffAll, index)
    }
  }
}

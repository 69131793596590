<template>
  <div id="app" :class="vueAppClasses" @contextmenu.prevent>
    <div class="grain"></div>
		<router-view />
    <CookieBanner @cookies-accepted="initializeKakaoSdk"/>
    <Intro />
	</div>
</template>

<script>
import Intro from '@/views/pages/web/intro/SplashScreen.vue'
import CookieBanner from '@/layouts/official/banners/CookieBanner.vue'
import jwt from "@/http/requests/auth/jwt/index.js"
export default {
  data() {
    return {
      timerId: null,
      board: {
        title: 'Ylohpp',
        keyword: '웹사이트, 반응형 디자인, 랜딩페이지, erp, crm, 홈피, 홈페이지, 웹에이전시, si, it기업, Web Agency'
      }
    }
  },
  metaInfo() {
    return {
      title: 'Ylohpp',
      titleTemplate: '%s | Behaviors Shape Tomorrow™',
      meta: [
          {
            property: 'og:title',
            content: 'Ylohpp',
            template: chunk => `${chunk} | www.yellowhippy.kr`,
            vmid: 'og:title'
          }
      ]
    }
  },
  components: {
    Intro,
    CookieBanner
  },
  computed: {
    isSplash() {
      return this.$store.state.isSplash
    },
    vueAppClasses() {
      // const widths = [1920, 1600, 1024, 768];
      // for (let width of widths) {
      //     if (window.innerWidth <= width) {
      //         return `__w${width}`;
      //     }
      // }
    },
    weight() {
      return this.$store.state.weight
    }
  },
  methods: {
    initializeKakaoSdk() {
      if (window.Kakao && !window.Kakao.isInitialized()) {
        try {
          window.Kakao.init('a00045c4b49c7f9be84e8b976e1af322'); // 여기에 실제 앱 키를 넣어주세요
        } catch (error) {
          console.error('Kakao SDK 초기화 오류:', error);
        }
      }
    },
    updateHtmlClass() {
      const width = window.innerWidth;
      let classNames = [];
      if (width >= 2560) {
        classNames.push('w2560');
      } else if (width >= 1920) {
        classNames.push('w1920');
      } else if (width >= 1600) {
        classNames.push('w1600');
      } else if (width >= 1600) {
        classNames.push('w1280');
      } else if (width >= 1280) {
        classNames.push('w1280');
      } else if (width >= 1024) {
        classNames.push('w1024');
      } else if (width >= 768) {
        classNames.push('w768');
      } else if (width >= 600) {
        classNames.push('w600');
      } else {
        classNames.push('w390');
      }
      document.documentElement.className = classNames.join(' ');
    },
    updateWidth() {
      this.$store.commit('SET_INNER_WIDTH', window.innerWidth * this.weight)
      this.$store.commit('SET_INNER_HEIGHT', window.innerHeight * this.weight)
      this.updateHtmlClass()
    },
    setTimer() {

    },
    clearTimer() {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  },
  async created() {
    window.addEventListener("DOMContentLoaded", (ev) => {
      const { innerHeight } = window;
      document.documentElement.style.setProperty("--app-height",`${innerHeight}px`);
    });
  },
  mounted() {
    this.updateWidth()
    this.setTimer()
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.onload = null
    this.clearTimer()
    window.removeEventListener('resize', this.updateWidth);
  },
}
</script>
<template>
    <div class="splash" :style="{width: isSplash ? '100%' : '0vh'}" style="position: fixed; top: 0; left: 0; height: var(--app-height); z-index: 998; background: #201c20; transition: width 0.6s ease; overflow: hidden; z-index: 999999;">
    <!-- <div  class="splash" :style="{height: isSplash ? 'var(--app-height)' : 0}" style="position: fixed; top: 0; left: 0; width: 100%; max-width: 100vw; display: flex; flex-direction: column; justify-content: space-between; align-items: flex-start; z-index: 998; background: #201c20; transition: all 0.6s ease; overflow: hidden; z-index: 999999"> -->
        <div :style="{justifyContent: (!pageTitle || (pageTitle && pageTitle == 'home')) ? 'flex-start' : 'center'}" style="position: relative; width: 100%; height: var(--app-height); display: flex; flex-direction: column; align-items: flex-start; padding: 54px 21px;">
            <div v-if="pageTitle && (pageTitle != 'home')" style="width: calc(100vw - 42px); overflow: hidden; white-space: nowrap;">
                <div class="fixed-content" style="width: 100%; display: flex; justify-content: center; animation: none">
                    <div class="t--title" ref="title" style="transition: all 0.5s ease; letter-spacing: -0.07em; color: white; text-align: center">
                        <h1 v-if="$route.path != 'THE SUMMER'" style="text-transform: uppercase;">{{pageTitle}}</h1>
                        <h1 v-else style="text-transform: uppercase; display: flex; flex-direction: column">
                            <span>{{pageTitle.split(' ')[0]}} {{pageTitle.split(' ')[1]}}</span>
                            <span>{{pageTitle.split(' ')[2]}}</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div v-if="!pageTitle || (pageTitle == 'home')" class="marquee">
                <div class="marquee-content" ref="marquee" style="display: flex">
                    <span v-for="i in slideItems" :key="i" style="line-height: 0.8; letter-spacing: -1px; display: flex; align-items: flex-end; color: white; white-space : nowrap">
                        <p style="font-size: 13.6vw; letter-spacing: -0.03em; line-height: 0.8;">
                            BREAK THE STERE<span><img src="@/assets/images/official/logo-icon.svg" alt="기업 로고 아이콘" style="width: 10.1vw;"></span>TYPES 
                        </p>
                        <p class="telemark" style="margin-right: 50px; margin-left: 3px; line-height: 0.6;">TM</p>
                    </span>
                </div>
            </div>
            <div v-if="!pageTitle || (pageTitle == 'home')" style="width: calc(100vw - 42px); overflow: hidden; white-space: nowrap;">
                <div class="f-space-between sub_slogan__wrap" style="min-width: 100%; width: 100%; padding: 5px 0;">
                    <p class="t--300" style="color: white; line-height: 1.1;">
                        FREEDOM IN ACTION,<br>
                        LIFE IN CREATION
                    </p>
                    <p class="t--300" style="color: white; line-height: 1.1; text-align: right">
                        Est. Sep<br>©2K23
                    </p>
                </div>
            </div>
            <div v-if="!pageTitle || (pageTitle && pageTitle == 'home')" style="height: 8em">

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageTitle: null,
            slideItems: [1, 2, 3],
        }
    },
    watch: {
        isSplash() {
            if(this.isSplash) {
                this.pageTitle = this.isSplash
            } else {
                setTimeout(() => {
                    this.pageTitle = null
                }, 500);
            }
            console.log(this.isSplash)
        }
    },
    computed: {
        innerWidth() {
            return this.$store.state.innerWidth
        },
        isSplash() {
            return this.$store.state.isSplash
        }
    },
    methods: {
        moveFirstItemToLast() {
            const firstItem = this.slideItems.shift();
            this.slideItems.push(firstItem);
        },
        createDynamicAnimation() {
            const marquee = this.$refs.marquee;
            const marqueeWidth = marquee.children[0].offsetWidth; // 요소의 너비를 계산합니다

            // 요소의 너비에 따라 애니메이션 거리를 계산합니다
            const moveDistance = marqueeWidth

            // 동적으로 @keyframes 규칙을 생성합니다
            const keyframes = `
            @keyframes marquee-1 {
                from { transform: translate3d(0, 0, 0); }
                to { transform: translate3d(-${moveDistance}px, 0, 0); }
            }`;

            // <style> 태그를 찾거나 새로 생성합니다
            let styleTag = document.querySelector('#dynamic-animation-style');
            if (!styleTag) {
                styleTag = document.createElement('style');
                styleTag.id = 'dynamic-animation-style';
                document.head.appendChild(styleTag);
            }

            // 생성된 규칙을 <style> 태그에 삽입합니다
            styleTag.textContent = keyframes;

            // 애니메이션을 요소에 적용합니다
            marquee.style.animation = 'marquee-1 20s linear infinite';
        },
    },
    mounted() {
        this.createDynamicAnimation();
        window.addEventListener('resize', this.createDynamicAnimation);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.createDynamicAnimation);
    }
}
</script>
<style lang="scss">
    .splash {
        @media screen and (max-width: 1920px) {
            .telemark {
                font-size: 1.3em;
            }
        }
        @media screen and (max-width: 768px) {
            .telemark {
                font-size: 1.1em;
            }
        }
    }
</style>
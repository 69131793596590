/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js"

const state = {
  isSplash: null,
  toast: {
    state: false
  },
  weight: 1,
  innerWidth: 0,
  innerHeight: 0,
  scrollY: 0,
  switchState: 0,
  isPrint: {
    state: false
  },
  isArchive: false,
  isHamburger: false,
  isStickyArea: false,
  isStickyArea: false,
  activeMenu: null,
  stickyY: 0,
  deltaY: null,
  cursorType: null,
  isRightNav: false,
  cunsultIndex: 0,
  isChatbox: null,
  isDarkMode: false,
  isCustomerModal: false,
  isProductModal: false,
  isStuck: false,
  permitCount: false,
  userInfo: null,
  productList: [
    {   
      'id': 1,
      'name': '제품 제목1',
      'img_path': 'project.jpg',
      'tag': [
          '웹사이트',
          'ERP',
          '디지털 에이전시'
      ],
      'content': '여기에 콘텐츠 내용이 들어갑니다.'
    },{
      'id': 2,
      'name': '제품 제목2',
      'img_path': 'sven-mieke-fteR0e2BzKo-unsplash.jpg',
      'tag': [
          '웹사이트',
          'ERP',
          '디지털 에이전시'
      ],
      'content': '여기에 콘텐츠 내용이 들어갑니다.'
    },{
      'id': 3,
      'name': '제품 제목3',
      'img_path': 'adomas-aleno-4vrZpOo7fTc-unsplash.jpg',
      'tag': [
          '웹사이트',
          'ERP',
          '디지털 에이전시'
      ],
      'content': '여기에 콘텐츠 내용이 들어갑니다.'
    },{   
      'id': 4,
      'name': '제품 제목4',
      'img_path': 'project.jpg',
      'tag': [
          '웹사이트',
          'ERP',
          '디지털 에이전시'
      ],
      'content': '여기에 콘텐츠 내용이 들어갑니다.'
    },{
      'id': 5,
      'name': '제품 제목5',
      'img_path': 'sven-mieke-fteR0e2BzKo-unsplash.jpg',
      'tag': [
          '웹사이트',
          'ERP',
          '디지털 에이전시'
      ],
      'content': '여기에 콘텐츠 내용이 들어갑니다.'
    }
  ],
  userList: [
    {
        name: 'Jacob',
        id: 0,
        avatar: 'jacob-unscreen'
    }
  ],
  activeTask: null,
  chatHistory: [],
  alertList: [],
  processCount: 0,
  keyword: {
    'query': ''
  },
  stockKeyword: {
    'query': ''
  },
  processKeyword: {
    'query': ''
  },
  activeBom: null,
  activeProcess: null,
  activeProcessList: [],
  chatComment: [ 
    {
      id: 1,
      type: 1,
      list: [
        {   
          'id': 1,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '문의 주셔서 감사합니다! <br> 우리 옐로히피가 최선을 다해 도와드릴게요😃',
          'start_index': true,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        },{
          'id': 2,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '의뢰하실 프로젝트는 어떤 유형인가요?',
          'start_index': false,
          'btn': false,
          'time': true,
          'isHide': false,
          'create_time': null
        },
      ]
    },{
      id: 2,
      type: 1,
      list: [
        {
          'id': 3,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '프로젝트라면<br>우리 팀이 최고의 선택이예요!😎<br>함께할 생각에 벌써부터 설레는데요?!',
          'start_index': true,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        }, 
        {
          'id': 4,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '예상되는 프로젝트 비용은 얼마인가요?',
          'start_index': false,
          'btn': false,
          'time': true,
          'isHide': false,
          'create_time': null
        }
      ]
    },{
      id: 3,
      type: 1,
      list: [
        {
          'id': 5,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '시간은 금!🕧<br>비지니스에서 시간약속은 빼놓을 수 없겠죠?<br>예상하는 프로젝트 기간을 알려주세요!',
          'start_index': true,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        }
      ]
    },{
      id: 4,
      type: 1,
      list: [
        {
          'id': 6,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '감사합니다.',
          'start_index': true,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        },{
          'id': 7,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '의뢰가 거의 끝났어요! 마지막으로 아래 내용을 기재해주시겠어요?💪',
          'start_index': false,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        }
      ]
    },{
      id: 5,
      type: 1,
      list: [
        {
          'id': 8,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '고생 많으셨어요:)',
          'start_index': true,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        },{
          'id': 9,
          'type': 0,
          'avatar_name': 'Jacob',
          'comment': '담담자가 배정된 후 빠른 시일 내로 연락드릴게요!',
          'start_index': false,
          'btn': false,
          'time': false,
          'isHide': false,
          'create_time': null
        }
      ]
    }
  ],
  taskList: [
    {
      'id': 2,
      'company_id': 1,
      'state': 0,
      'admin': 'Jacob',
      'detail': '기업형 디자인 미팅',
      'client': 'DreamHub',
      'corp_detail': 'DreamHub는 스마트 홈 기기를 중심으로 한 자사 솔루션 업체이고, "편리함을 넘어 안전까지"라는 기업정신을 모토로, "SafeHome"이라는 어플리케이션을 운영중입니다.',
      'manager': '김석규',
      'phone_number': '010-1234-1234',
      'mobile_number': '010-1234-1234',
      'fax_number': '0502-1234-1234',
      'email': 'admin@dremhub.ex',
      'work_type': [2],
      'level': '2',
      'charge': '',
      'amount': 12000000,
      'avatar': 'jacob-unscreen',
      'create_time': '2024-05-03'
    },{
      'id': 1,
      'company_id': 1,
      'state': 4,
      'admin': 'Jacob',
      'detail': '기업형 디자인 미팅',
      'client': 'DreamHub',
      'corp_detail': 'DreamHub는 스마트 홈 기기를 중심으로 한 자사 솔루션 업체이고, "편리함을 넘어 안전까지"라는 기업정신을 모토로, "SafeHome"이라는 어플리케이션을 운영중입니다.',
      'manager': '김석규',
      'phone_number': '010-1234-1234',
      'mobile_number': '010-1234-1234',
      'fax_number': '0502-1234-1234',
      'email': 'admin@dremhub.ex',
      'work_type': [3],
      'level': '2',
      'charge': '',
      'amount': 30000000,
      'avatar': 'jacob-unscreen',
      'create_time': '2024-04-01'
    }
  ],
  activeTaskList: [],
  noteList: [
    {
      'id': 1,
      'note_type_id': 1,
      'task_id': 1,
      'detail': '',
      'Arthur': 'Jacob Gwak',
      'comment': []
    },{
      'id': 2,
      'note_type_id': 2,
      'task_id': 1,
      'detail': '안녕하세요. 프로젝트 진행 관련해 문의드립니다. 차주 언제쯤 미팅 가능할까요?',
      'Arthur': 'Jacob Gwak',
      'comment': [
        {
          'user_id': 1,
          'user_name': 'Jacob',
          'avatar': 'jacob-unscreen',
          'detail': '자사 CRM 개발 문의건인데 미팅 스케쥴 언제로 조율할까요?',
          'tag': 'Arthur'
        }
      ]
    },{
      'id': 3,
      'note_type_id': 2,
      'task_id': 1,
      'detail': '',
      'Arthur': 'Jacob Gwak',
      'comment': []
    },
  ],
  activeNoteList: [],
  quoteInfo: {
    process_info: [],
    resource_info: []
  }, 
  activeList: [],
  bomList: [
    {
      "id": 1,
      "name": "에어 필터",
      "detail": "엔진 공기 공급",
      "option1": "부직포",
      "option2": "226x254x20",
      "option3": "화이트",
      "option4": "먼지 주의, 재질별 가공 다름"
    },
    {
      "id": 2,
      "name": "오일 필터",
      "detail": "엔진 오일 정화",
      "option1": "폴리에스터",
      "option2": "125x80x60",
      "option3": "그린",
      "option4": "가연성, 오일별 필터 제작"
    },
    {
      "id": 3,
      "name": "연료 필터",
      "detail": "연료 정화",
      "option1": "폴리프로필렌",
      "option2": "80x80x50",
      "option3": "화이트",
      "option4": "가연성, 연료별 필터 제작"
    },
    {
      "id": 4,
      "name": "에어백 필터",
      "detail": "에어백 작동 시 탑승자 보호",
      "option1": "폴리에스테르",
      "option2": "150x100x50",
      "option3": "그레이",
      "option4": "센서 영향, 재질별 가공 다름"
    },
    {
      "id": 5, 
      "name": "연료 탱크 캡",
      "detail": "연료 탱크 밀봉",
      "option1": "폴리프로필렌",
      "option2": "100x100x50",
      "option3": "블랙",
      "option4": "누출 방지, 내부 청소"
    },
    {
      "id": 6, 
      "name": "엔진 커버",
      "detail": "엔진 보호",
      "option1": "ABS 플라스틱",
      "option2": "300x250x100",
      "option3": "블랙",
      "option4": "열 방출, 재질별 열 변형 주의"
    },
    {
      "id": 7, 
      "name": "배터리 커버",
      "detail": "배터리 보호",
      "option1": "폴리프로필렌",
      "option2": "200x150x100",
      "option3": "블랙",
      "option4": "열 방출, 재질별 열 변형 주의"
    },
    {
      "id": 8, 
      "name": "헤드라이트 렌즈",
      "detail": "헤드라이트 빛 투과",
      "option1": "폴리카보네이트",
      "option2": "200x150x50",
      "option3": "클리어",
      "option4": "조명 방향/밝기, 충격 주의"
    },
    {
      "id": 9, 
      "name": "테일라이트 렌즈",
      "detail": "테일라이트 빛 투과",
      "option1": "폴리카보네이트",
      "option2": "150x100x50",
      "option3": "레드",
      "option4": "후방 시인성, 충격 주의"
    },
    {
      "id": 10, 
      "name": "도어 핸들",
      "detail": "차량 출입",
      "option1": "ABS 플라스틱",
      "option2": "150x50x50",
      "option3": "블랙",
      "option4": "개폐 기능, 내구성 주의"
    },
    {
      "id": 11,
      "name": "에어컨 필터",
      "detail": "차량 실내 공기 정화",
      "option1": "활성탄",
      "option2": "250x200x30",
      "option3": "화이트",
      "option4": "먼지 주의, 교체 시기 확인"
    },
    {
      "id": 12,
      "name": "연료 펌프",
      "detail": "연료 공급",
      "option1": "스테인리스 스틸",
      "option2": "100x50x50",
      "option3": "실버",
      "option4": "가연성, 밀폐 확인"
    },
    {
      "id": 13, 
      "name": "배기 매니폴드",
      "detail": "배기 가스 배출",
      "option1": "주철",
      "option2": "300x200x100",
      "option3": "블랙",
      "option4": "열 방출, 균열 주의"
    },
    {
      "id": 14, 
      "name": "라디에이터",
      "detail": "엔진 냉각",
      "option1": "알루미늄",
      "option2": "400x300x50",
      "option3": "실버",
      "option4": "부식 주의, 냉각수 관리"
    },
    {
      "id": 15, 
      "name": "스파크 플러그",
      "detail": "엔진 점화",
      "option1": "백금",
      "option2": "10cm",
      "option3": "실버",
      "option4": "전극 마모 주의, 차종별 제작"
    },
    {
      "id": 16, 
      "name": "클러치",
      "detail": "동력 전달",
      "option1": "강철",
      "option2": "250mm",
      "option3": "실버",
      "option4": "마모 주의, 교체 시기 확인"
    },
    {
      "id": 17, 
      "name": "트랜스미션",
      "detail": "동력 변환 및 전달",
      "option1": "알루미늄",
      "option2": "400x300x200",
      "option3": "실버",
      "option4": "윤활유 관리, 이상 소리 주의"
    },
    {
      "id": 18, 
      "name": "디퍼렌셜 기어",
      "detail": "동력 분배",
      "option1": "강철",
      "option2": "200mm",
      "option3": "실버",
      "option4": "윤활유 관리, 이상 진동 주의"
    },
    {
      "id": 19, 
      "name": "드라이브 샤프트",
      "detail": "동력 전달",
      "option1": "강철",
      "option2": "100cm",
      "option3": "실버",
      "option4": "균열 주의, 진동 관리"
    },
    {
      "id": 20, 
      "name": "서스펜션 시스템",
      "detail": "차량 승차감 및 안정성",
      "option1": "스프링, 쇼크업소버",
      "option2": "40cm",
      "option3": "블랙",
      "option4": "마모 주의, 교체 시기 확인"
    }
  ],
  processList: [
    {
      "id": 1,
      "product_id": 1,
      "process_name": "에어 필터 제작 (부직포)",
      "price": 10000,
      "supply_value": 8000,
      "tax": 1000,
      "resource": [
        {
          "resource_name": "부직포 필터",
          "option1": "226x254x20",
          "option2": "화이트",
          "option3": null,
          "option4": null,
          "memo": "먼지 주의, 폴리프로필렌 재질, 멜트블로운 방식, 전기적 특성 고려",
          "quantity": 1,
          "price": 5000,
          "supply_value": 4000,
          "tax": 500
        },
        {
          "resource_name": "프레임",
          "option1": "ABS 플라스틱",
          "option2": "230x260x25",
          "option3": "블랙",
          "option4": null,
          "memo": "사출 성형, 내구성 및 내열성 고려",
          "quantity": 1,
          "price": 3000,
          "supply_value": 2400,
          "tax": 300
        },
        {
          "resource_name": "접착제",
          "option1": null,
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "핫멜트 접착, 접착 강도 및 내열성 고려",
          "quantity": 1,
          "price": 2000,
          "supply_value": 1600,
          "tax": 200
        }
      ]
    },
    {
      "id": 2,
      "product_id": 2,
      "process_name": "오일 필터 제작 (폴리에스터)",
      "price": 8000,
      "supply_value": 6400,
      "tax": 800,
      "resource": [
        {
          "resource_name": "폴리에스터 필터",
          "option1": "125x80x60",
          "option2": "그린",
          "option3": null,
          "option4": null,
          "memo": "오일 흡수력 고려, 내구성 및 내열성 고려",
          "quantity": 1,
          "price": 4000,
          "supply_value": 3200,
          "tax": 400
        },
        {
          "resource_name": "필터 케이스",
          "option1": "금속",
          "option2": "130x85x65",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려",
          "quantity": 1,
          "price": 3000,
          "supply_value": 2400,
          "tax": 300
        },
        {
          "resource_name": "O-링",
          "option1": "고무",
          "option2": "120x80x5",
          "option3": "블랙",
          "option4": null,
          "memo": "내유성 및 내열성 고려",
          "quantity": 1,
          "price": 1000,
          "supply_value": 800,
          "tax": 100
        }
      ]
    },
    {
      "id": 3,
      "product_id": 3,
      "process_name": "연료 필터 제작 (폴리프로필렌)",
      "price": 5000,
      "supply_value": 4000,
      "tax": 500,
      "resource": [
        {
          "resource_name": "폴리프로필렌 필터",
          "option1": "80x80x50",
          "option2": "화이트",
          "option3": null,
          "option4": null,
          "memo": "연료 불순물 제거, 내구성 및 내열성 고려",
          "quantity": 1,
          "price": 3000,
          "supply_value": 2400,
          "tax": 300
        },
        {
          "resource_name": "필터 홀더",
          "option1": "금속",
          "option2": "85x85x55",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려",
          "quantity": 1,
          "price": 2000,
          "supply_value": 1600,
          "tax": 200
        }
      ]
    },
    {
      "id": 4,
      "product_id": 4,
      "process_name": "에어백 필터 제작 (폴리에스테르)",
      "price": 12000,
      "supply_value": 9600,
      "tax": 1200,
      "resource": [
        {
          "resource_name": "폴리에스테르 필터",
          "option1": "150x100x50",
          "option2": "그레이",
          "option3": null,
          "option4": null,
          "memo": "미세먼지 제거, 내구성 및 내열성 고려",
          "quantity": 1,
          "price": 6000,
          "supply_value": 4800,
          "tax": 600
        },
        {
          "resource_name": "필터 센서",
          "option1": "전자 부품",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "에어백 작동 감지, 내구성 및 내열성 고려",
          "quantity": 1,
          "price": 5000,
          "supply_value": 4000,
          "tax": 500
        },
        {
          "resource_name": "필터 케이스",
          "option1": "플라스틱",
          "option2": "155x105x55",
          "option3": "블랙",
          "option4": null,
          "memo": "내구성 및 내열성 고려",
          "quantity": 1,
          "price": 1000,
          "supply_value": 800,
          "tax": 100
        }
      ]
    },{
      "id": 5,
      "product_id": 5,
      "process_name": "연료 탱크 캡 제작 (폴리프로필렌)",
      "price": 3000,
      "supply_value": 2400,
      "tax": 300,
      "resource": [
        {
          "resource_name": "폴리프로필렌 캡",
          "option1": "100x100x50",
          "option2": "블랙",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 연료 증기 방출 방지",
          "quantity": 1,
          "price": 2000,
          "supply_value": 1600,
          "tax": 200
        },
        {
          "resource_name": "캡 씰",
          "option1": "고무",
          "option2": "95x95x5",
          "option3": "블랙",
          "option4": null,
          "memo": "내유성 및 내열성 고려, 연료 누출 방지",
          "quantity": 1,
          "price": 1000,
          "supply_value": 800,
          "tax": 100
        }
      ]
    },
    {
      "id": 6,
      "product_id": 6,
      "process_name": "엔진 커버 제작 (ABS 플라스틱)",
      "price": 5000,
      "supply_value": 4000,
      "tax": 500,
      "resource": [
        {
          "resource_name": "ABS 플라스틱 커버",
          "option1": "300x250x100",
          "option2": "블랙",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 엔진 소음 감소",
          "quantity": 1,
          "price": 3000,
          "supply_value": 2400,
          "tax": 300
        },
        {
          "resource_name": "커버 고정 볼트",
          "option1": "금속",
          "option2": "M6x20",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려, 커버 고정",
          "quantity": 4,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        },
        {
          "resource_name": "고정 와셔",
          "option1": "금속",
          "option2": "M6",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려, 볼트 고정",
          "quantity": 4,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },
    {
      "id": 7,
      "product_id": 7,
      "process_name": "배터리 커버 제작 (폴리프로필렌)",
      "price": 2000,
      "supply_value": 1600,
      "tax": 200,
      "resource": [
        {
          "resource_name": "폴리프로필렌 커버",
          "option1": "200x150x100",
          "option2": "블랙",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 배터리 보호",
          "quantity": 1,
          "price": 1200,
          "supply_value": 960,
          "tax": 120
        },
        {
          "resource_name": "커버 고정 클립",
          "option1": "플라스틱",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 커버 고정",
          "quantity": 2,
          "price": 400,
          "supply_value": 320,
          "tax": 40
        }
      ]
    },
    {
      "id": 8,
      "product_id": 8,
      "process_name": "헤드라이트 렌즈 제작 (폴리카보네이트)",
      "price": 4000,
      "supply_value": 3200,
      "tax": 400,
      "resource": [
        {
          "resource_name": "폴리카보네이트 렌즈",
          "option1": "200x150x50",
          "option2": "클리어",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 빛 투과율 고려",
          "quantity": 1,
          "price": 2500,
          "supply_value": 2000,
          "tax": 250
        },
        {
          "resource_name": "렌즈 고정 링",
          "option1": "금속",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 렌즈 고정",
          "quantity": 1,
          "price": 1500,
          "supply_value": 1200,
          "tax": 150
        }
      ]
    },
    {
      "id": 9,
      "product_id": 9,
      "process_name": "테일라이트 렌즈 제작 (폴리카보네이트)",
      "price": 3000,
      "supply_value": 2400,
      "tax": 300,
      "resource": [
        {
          "resource_name": "폴리카보네이트 렌즈",
          "option1": "150x100x50",
          "option2": "레드",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 빛 투과율 고려",
          "quantity": 1,
          "price": 2000,
          "supply_value": 1600,
          "tax": 200
        },
        {
          "resource_name": "렌즈 고정 나사",
          "option1": "금속",
          "option2": "M4x12",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려, 렌즈 고정",
          "quantity": 2,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },{
      "id": 10,
      "product_id": 10,
      "process_name": "도어 핸들 제작 (ABS 플라스틱)",
      "price": 2500,
      "supply_value": 2000,
      "tax": 250,
      "resource": [
        {
          "resource_name": "ABS 플라스틱 핸들",
          "option1": "150x50x50",
          "option2": "블랙",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 사용 편의성 고려",
          "quantity": 1,
          "price": 1500,
          "supply_value": 1200,
          "tax": 150
        },
        {
          "resource_name": "핸들 고정 나사",
          "option1": "금속",
          "option2": "M4x16",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려, 핸들 고정",
          "quantity": 2,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        },
        {
          "resource_name": "핸들 스프링",
          "option1": "금속",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 핸들 복원",
          "quantity": 1,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },
    {
      "id": 11,
      "product_id": 11,
      "process_name": "도어 힌지 제작 (금속)",
      "price": 3000,
      "supply_value": 2400,
      "tax": 300,
      "resource": [
        {
          "resource_name": "금속 힌지",
          "option1": "100x50x30",
          "option2": "실버",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 도어 개폐 부드러움",
          "quantity": 2,
          "price": 1500,
          "supply_value": 1200,
          "tax": 150
        },
        {
          "resource_name": "힌지 고정 볼트",
          "option1": "금속",
          "option2": "M5x12",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려, 힌지 고정",
          "quantity": 4,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        },
        {
          "resource_name": "힌지 고정 와셔",
          "option1": "금속",
          "option2": "M5",
          "option3": "실버",
          "option4": null,
          "memo": "내구성 및 내열성 고려, 볼트 고정",
          "quantity": 4,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },
    {
      "id": 12,
      "product_id": 12,
      "process_name": "윈도우 레귤레이터 제작 (금속)",
      "price": 4000,
      "supply_value": 3200,
      "tax": 400,
      "resource": [
        {
          "resource_name": "금속 레귤레이터",
          "option1": "200x100x50",
          "option2": "실버",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 윈도우 개폐 작동",
          "quantity": 1,
          "price": 2500,
          "supply_value": 2000,
          "tax": 250
        },
        {
          "resource_name": "레귤레이터 모터",
          "option1": "전자 부품",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 윈도우 작동",
          "quantity": 1,
          "price": 1000,
          "supply_value": 800,
          "tax": 100
        },
        {
          "resource_name": "레귤레이터 케이블",
          "option1": "금속",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 윈도우 연결",
          "quantity": 1,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },
    {
      "id": 13,
      "product_id": 13,
      "process_name": "윈도우 글래스 제작 (유리)",
      "price": 2000,
      "supply_value": 1600,
      "tax": 200,
      "resource": [
        {
          "resource_name": "유리 글래스",
          "option1": "250x150x5",
          "option2": "클리어",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 빛 투과율 고려",
          "quantity": 1,
          "price": 1500,
          "supply_value": 1200,
          "tax": 150
        },
        {
          "resource_name": "글래스 고정 실",
          "option1": "고무",
          "option2": null,
          "option3": null,
          "option4": null,
          "memo": "내구성 및 내열성 고려, 글래스 고정",
          "quantity": 1,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },
    {
      "id": 14,
      "product_id": 14,
      "process_name": "시트 쿠션 제작 (폼)",
      "price": 1500,
      "supply_value": 1200,
      "tax": 150,
      "resource": [
        {
          "resource_name": "폼 쿠션",
          "option1": "500x500x100",
          "option2": "블랙",
          "option3": null,
          "option4": null,
          "memo": "편안함 및 내구성 고려",
          "quantity": 1,
          "price": 1000,
          "supply_value": 800,
          "tax": 100
        },
        {
          "resource_name": "쿠션 커버",
          "option1": "패브릭",
          "option2": "블랙",
          "option3": null,
          "option4": null,
          "memo": "내구성 및 통기성 고려",
          "quantity": 1,
          "price": 500,
          "supply_value": 400,
          "tax": 50
        }
      ]
    },{
        "id": 15,
        "product_id": 15,
        "process_name": "시트 백 제작 (금속)",
        "price": 2000,
        "supply_value": 1600,
        "tax": 200,
        "resource": [
          {
            "resource_name": "금속 백",
            "option1": "400x400x100",
            "option2": "실버",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 시트 기울기 조절",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "백 고정 볼트",
            "option1": "금속",
            "option2": "M6x20",
            "option3": "실버",
            "option4": null,
            "memo": "내구성 및 내열성 고려, 백 고정",
            "quantity": 4,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          }
        ]
      },
      {
        "id": 16,
        "product_id": 16,
        "process_name": "스티어링 휠 제작 (가죽)",
        "price": 3500,
        "supply_value": 2800,
        "tax": 350,
        "resource": [
          {
            "resource_name": "스티어링 휠 프레임",
            "option1": "플라스틱",
            "option2": "블랙",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 핸들 조작",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "가죽 커버",
            "option1": "블랙",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "편안함 및 내구성 고려",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "휠 고정 나사",
            "option1": "금속",
            "option2": "M5x16",
            "option3": "실버",
            "option4": null,
            "memo": "내구성 및 내열성 고려, 휠 고정",
            "quantity": 2,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          }
        ]
      },
      {
        "id": 17,
        "product_id": 17,
        "process_name": "기어 노브 제작 (플라스틱)",
        "price": 1000,
        "supply_value": 800,
        "tax": 100,
        "resource": [
          {
            "resource_name": "플라스틱 노브",
            "option1": "블랙",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 기어 변속",
            "quantity": 1,
            "price": 600,
            "supply_value": 480,
            "tax": 60
          },
          {
            "resource_name": "노브 고정 나사",
            "option1": "금속",
            "option2": "M4x12",
            "option3": "실버",
            "option4": null,
            "memo": "내구성 및 내열성 고려, 노브 고정",
            "quantity": 1,
            "price": 400,
            "supply_value": 320,
            "tax": 40
          }
        ]
      },
      {
        "id": 18,
        "product_id": 18,
        "process_name": "계기판 제작 (LCD)",
        "price": 5000,
        "supply_value": 4000,
        "tax": 500,
        "resource": [
          {
            "resource_name": "LCD 패널",
            "option1": "100x50x5",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 정보 표시",
            "quantity": 1,
            "price": 3000,
            "supply_value": 2400,
            "tax": 300
          },
          {
            "resource_name": "계기판 프레임",
            "option1": "플라스틱",
            "option2": "블랙",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 패널 고정",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "계기판 조명",
            "option1": "LED",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 정보 가시성",
            "quantity": 1,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          }
        ]
      },
      {
        "id": 19,
        "product_id": 19,
        "process_name": "라디오 제작 (디지털)",
        "price": 2000,
        "supply_value": 1600,
        "tax": 200,
        "resource": [
          {
            "resource_name": "디지털 라디오 모듈",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 라디오 수신",
            "quantity": 1,
            "price": 1200,
            "supply_value": 960,
            "tax": 120
          },
          {
            "resource_name": "라디오 스피커",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 소리 출력",
            "quantity": 2,
            "price": 400,
            "supply_value": 320,
            "tax": 40
          },
          {
            "resource_name": "라디오 프레임",
            "option1": "플라스틱",
            "option2": "블랙",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 모듈 고정",
            "quantity": 1,
            "price": 400,
            "supply_value": 320,
            "tax": 40
          }
        ]
      },{
        "id": 20,
        "product_id": 20,
        "process_name": "네비게이션 제작 (GPS)",
        "price": 4000,
        "supply_value": 3200,
        "tax": 400,
        "resource": [
          {
            "resource_name": "GPS 모듈",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 위치 정보 수신",
            "quantity": 1,
            "price": 2500,
            "supply_value": 2000,
            "tax": 250
          },
          {
            "resource_name": "네비게이션 화면",
            "option1": "LCD",
            "option2": "7인치",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 정보 표시",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "네비게이션 프레임",
            "option1": "플라스틱",
            "option2": "블랙",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 모듈 고정",
            "quantity": 1,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          }
        ]
      },
      {
        "id": 21,
        "product_id": 21,
        "process_name": "에어컨 제작 (냉매)",
        "price": 6000,
        "supply_value": 4800,
        "tax": 600,
        "resource": [
          {
            "resource_name": "에어컨 컴프레서",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 냉매 순환",
            "quantity": 1,
            "price": 3000,
            "supply_value": 2400,
            "tax": 300
          },
          {
            "resource_name": "에어컨 냉각 팬",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 공기 냉각",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "에어컨 송풍구",
            "option1": "플라스틱",
            "option2": "블랙",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 냉각 공기 배출",
            "quantity": 4,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "냉매",
            "option1": "액체",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "냉각 효과",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          }
        ]
      },
      {
        "id": 22,
        "product_id": 22,
        "process_name": "히터 제작 (열선)",
        "price": 2500,
        "supply_value": 2000,
        "tax": 250,
        "resource": [
          {
            "resource_name": "히터 코일",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 열 발생",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "히터 팬",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 열 분산",
            "quantity": 1,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "히터 송풍구",
            "option1": "플라스틱",
            "option2": "블랙",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 열 공기 배출",
            "quantity": 2,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          }
        ]
      },
      {
        "id": 23,
        "product_id": 23,
        "process_name": "와이퍼 제작 (고무)",
        "price": 1000,
        "supply_value": 800,
        "tax": 100,
        "resource": [
          {
            "resource_name": "와이퍼 블레이드",
            "option1": "고무",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 유리 청소",
            "quantity": 2,
            "price": 600,
            "supply_value": 480,
            "tax": 60
          },
          {
            "resource_name": "와이퍼 모터",
            "option1": "전자 부품",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 와이퍼 작동",
            "quantity": 1,
            "price": 400,
            "supply_value": 320,
            "tax": 40
          }
        ]
      },{
        "id": 24,
        "product_id": 24,
        "process_name": "타이어 제작 (고무)",
        "price": 5000,
        "supply_value": 4000,
        "tax": 500,
        "resource": [
          {
            "resource_name": "고무 타이어",
            "option1": "185/65R15",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 주행 안전",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "휠",
            "option1": "금속",
            "option2": "15인치",
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 타이어 고정",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          }
        ]
      },
      {
        "id": 25,
        "product_id": 25,
        "process_name": "브레이크 시스템 제작 (금속)",
        "price": 7000,
        "supply_value": 5600,
        "tax": 700,
        "resource": [
          {
            "resource_name": "브레이크 디스크",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 제동력",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "브레이크 패드",
            "option1": "세라믹",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 제동력",
            "quantity": 4,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "브레이크 캘리퍼",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 패드 작동",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "브레이크 호스",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 유압 연결",
            "quantity": 4,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "브레이크 마스터 실린더",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 유압 작동",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          }
        ]
      },
      {
        "id": 26,
        "product_id": 26,
        "process_name": "서스펜션 제작 (금속)",
        "price": 6000,
        "supply_value": 4800,
        "tax": 600,
        "resource": [
          {
            "resource_name": "쇼크 업소버",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 충격 흡수",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "스프링",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 차체 지지",
            "quantity": 4,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "서스펜션 암",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 차체 연결",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "서스펜션 부싱",
            "option1": "고무",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 소음 감소",
            "quantity": 8,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          }
        ]
      },{
        "id": 27,
        "product_id": 27,
        "process_name": "엔진 제작 (금속)",
        "price": 10000,
        "supply_value": 8000,
        "tax": 1000,
        "resource": [
          {
            "resource_name": "엔진 블록",
            "option1": "알루미늄",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 엔진 기반",
            "quantity": 1,
            "price": 3000,
            "supply_value": 2400,
            "tax": 300
          },
          {
            "resource_name": "엔진 헤드",
            "option1": "알루미늄",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 연소실",
            "quantity": 1,
            "price": 2000,
            "supply_value": 1600,
            "tax": 200
          },
          {
            "resource_name": "크랭크 샤프트",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 피스톤 연결",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "컨넥팅 로드",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 피스톤 연결",
            "quantity": 4,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "피스톤",
            "option1": "알루미늄",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 연소",
            "quantity": 4,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "캠 샤프트",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 밸브 작동",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "밸브",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 공기 및 배기 가스 제어",
            "quantity": 8,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          },
          {
            "resource_name": "엔진 오일 팬",
            "option1": "알루미늄",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 오일 저장",
            "quantity": 1,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "엔진 오일 필터",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 오일 정화",
            "quantity": 1,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          },
          {
            "resource_name": "엔진 냉각수",
            "option1": "액체",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "냉각 효과",
            "quantity": 1,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          }
        ]
      },
      {
        "id": 28,
        "product_id": 28,
        "process_name": "변속기 제작 (금속)",
        "price": 8000,
        "supply_value": 6400,
        "tax": 800,
        "resource": [
          {
            "resource_name": "변속기 케이스",
            "option1": "알루미늄",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 변속기 기반",
            "quantity": 1,
            "price": 2000,
            "supply_value": 1600,
            "tax": 200
          },
          {
            "resource_name": "기어",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 변속",
            "quantity": 6,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "샤프트",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 기어 연결",
            "quantity": 2,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "클러치",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 엔진과 변속기 연결",
            "quantity": 1,
            "price": 1500,
            "supply_value": 1200,
            "tax": 150
          },
          {
            "resource_name": "변속기 오일",
            "option1": "액체",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 윤활 및 냉각",
            "quantity": 1,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "변속기 필터",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 오일 정화",
            "quantity": 1,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          }
        ]
      },{
        "id": 29,
        "product_id": 29,
        "process_name": "차체 제작 (금속)",
        "price": 15000,
        "supply_value": 12000,
        "tax": 1500,
        "resource": [
          {
            "resource_name": "차체 프레임",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 차체 기반",
            "quantity": 1,
            "price": 5000,
            "supply_value": 4000,
            "tax": 500
          },
          {
            "resource_name": "도어",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 승객 탑승",
            "quantity": 4,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "후드",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 엔진 보호",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "트렁크",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 짐 적재",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "루프",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 지붕",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "바닥",
            "option1": "강철",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 바닥",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          },
          {
            "resource_name": "윈도우",
            "option1": "유리",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 시야 확보",
            "quantity": 6,
            "price": 500,
            "supply_value": 400,
            "tax": 50
          },
          {
            "resource_name": "도어 핸들",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 도어 개폐",
            "quantity": 4,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          },
          {
            "resource_name": "도어 힌지",
            "option1": "금속",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 도어 연결",
            "quantity": 4,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          },
          {
            "resource_name": "도어 실",
            "option1": "고무",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 방수 및 방음",
            "quantity": 4,
            "price": 250,
            "supply_value": 200,
            "tax": 25
          },
          {
            "resource_name": "차체 페인트",
            "option1": "도장",
            "option2": null,
            "option3": null,
            "option4": null,
            "memo": "내구성 및 내열성 고려, 외관",
            "quantity": 1,
            "price": 1000,
            "supply_value": 800,
            "tax": 100
          }
        ]
      },
  ],
  requestList: []
}
export default state 
